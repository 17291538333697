import React from "react";
import L from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import mapIcon from "./images/white-tree.png";

const useStyles = makeStyles((theme) => ({
  addressInfoContainer: {
    fontSize: "1rem",
    marginBottom: "1rem",
  },
}));

const myIcon = L.icon({
  iconUrl: mapIcon,
  iconSize: [75, 78],
  iconAnchor: [38, 77],
  popupAnchor: [0, -78],
});

const googleMapsDirectionsLink="https://www.google.com/maps/dir//Tangerine+Hair+Studio,+460+Lincoln+Ave+%2335,+San+Jose,+CA+95126/@37.3206099,-121.9128778,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808fccbbcddeb0c5:0x6c1de0f6fddcdb25!2m2!1d-121.9081804!2d37.3206021?entry=ttu"

const LeafletMap = () => {
  const classes = useStyles();
  const position = [37.32080604652246, -121.90814103599548];

  return (
    <Map center={position} zoom={17} style={{ width: "100%", height: "100%" }}>
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        maxZoom="20"
      />
      <Marker position={position} icon={myIcon}>
        <Popup>
          <div className={classes.addressInfoContainer}>
            Suite Meraki (in Tangerine Hair Studio)
            <br />
            460 Lincoln Ave #35
            <br /> San Jose, CA 95136
            <br />
          </div>
          <div className={classes.addressButtonContainer}>
            <Button
              variant="outlined"
              size="small"
              target="_blank"
              href={googleMapsDirectionsLink}
            >
              Get Directions
            </Button>
          </div>
        </Popup>
      </Marker>
    </Map>
  );
};

export default LeafletMap;
